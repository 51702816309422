import React, { useState } from "react";

// Chakra imports
import { Flex, Heading } from "@chakra-ui/react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { HSeparator } from "components/separator/Separator";
// Custom components
import img from "assets/img/Adventurus-Logo.png";
import { useDispatch } from "react-redux";
import { setExpand } from "reducer/Initializer";
export function SidebarBrand() {
  //   Chakra color mode
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
    dispatch(setExpand(!isExpanded));
  };
  return (
    <Flex align="center" direction="column">
      <Heading mb={5} className="sidebar_logo">
        {" "}
        {isExpanded === true && (
          <img
            src={img}
            style={{ width: "90%", height: "100%" }}
            alt="logo"
            className="sidebar_image"
          />
        )}
        <i onClick={toggleSidebar} className="icon_sidebar">
          {isExpanded === true ? <MenuIcon /> : <CloseIcon />}
        </i>
      </Heading>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
