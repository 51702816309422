import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import UserLayout from "layouts/user";
import { ChakraProvider } from "@chakra-ui/react";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider, useSelector } from "react-redux"; // Import Provider from react-redux
import { store } from "Store";
import theme from "theme/theme";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "Store";

function App() {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
    const users=useSelector((state)=>state.app.app.user);
  useNavigate();

  return (
    <>
      <ToastContainer />
      <Routes>
        {token && users?.role ? (
          // user?.role === "user" ? (
          //   <Route path="/*" element={<UserLayout />} />
          // ) : 
          // users?.role === "admin" ? (
          //   <Route path="/*" element={<AdminLayout />} />
          // ) :
           users?.role === "superadmin" ? (
            <Route path="/*" element={<UserLayout />} />
          ) : (
            <Route path="/*" element={<AdminLayout />} />
          )
        ) : (
          <Route path="/*" element={<AuthLayout />} />
        )}
      </Routes>
    </>
  );
}

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router basename="/">
              <App />
            </Router>
          </PersistGate>
        </Provider>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
