import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { IoMenuOutline } from "react-icons/io5";
// import { setCommonData } from "reducer/Initializer";
import { useDispatch, useSelector } from "react-redux";
import { setRoutePermission } from "reducer/Initializer";

function Sidebar(props) {
  const { routes, expand } = props;
  // const roles = JSON.parse(localStorage.getItem("roles"));
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const dispatch=useDispatch();
  // const users=useSelector((state)=>state.app.app.user);
  const role=useSelector((state)=>state.app.app.roles);

  let variantChange = "all 0.4 linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";

  useEffect(() => {
    let accumulatedRoutes = [];
  
    if (role.length !== 0) {
      // Process when roles are present
      role.forEach((test) => {
        dispatch(setRoutePermission(role));
  
        const filteredRoute = routes.filter(
          (route) => (
            // Check if the layout is not "/superadmin" and requiredRoles match
            (route.layout !== "/superadmin" && route.requiredRoles === test.perm_name && (
              route.name !== "TestTask" &&
              route.name !== "Template" &&
              route.name !== "Email Settings"
            )) ||
            // Check if the layout is "/superadmin" and route name does not match any of the specified names
            (route.layout === "/superadmin" && (
              route.name !== "TestTask" &&
              route.name !== "Template" &&
              route.name !== "Email Settings"
            ))
          )
        );
        accumulatedRoutes.push(...filteredRoute);
      });
    } else {
      // Process when roles are not present
      const filteredRoute = routes.filter(
        (route) => (
          // Check if the layout is not "/superadmin" and requiredRoles match
          route.layout === "/superadmin" && !route.requiredRoles
        )
      );
  
      accumulatedRoutes.push(...filteredRoute);
    }
  
    // Map the accumulated routes in the order of the original routes array
    const orderedRoutes = routes.map((route) =>
      accumulatedRoutes.find(
        (filteredRoute) => filteredRoute && filteredRoute.name === route.name
      )
    );
  
    setFilteredRoutes(orderedRoutes.filter(Boolean));
  }, []);
  

  return (
    <Box
      display={{ sm: "none", xl: "block" }}
      w="100%"
      position="fixed"
      minH="100%"
    >
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w={`${expand ? "220px" : "60px"}`}
        h="100vh"
        m={sidebarMargins}
        minH="100%"
        overflowX="hidden"
        boxShadow={shadow}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <Content routes={filteredRoutes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

export function SidebarResponsive(props) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let menuColor = useColorModeValue("gray.400", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { routes, expand } = props;
  // const roles = JSON.parse(localStorage.getItem("roles"));
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const dispatch=useDispatch();
  // const users=useSelector((state)=>state.app.app.user);
  const role=useSelector((state)=>state.app.app.roles);

   useEffect(() => {
    let accumulatedRoutes = [];
  
    if (role.length !== 0) {
      // Process when roles are present
      role.forEach((test) => {
        dispatch(setRoutePermission(role));
  
        const filteredRoute = routes.filter(
          (route) => (
            // Check if the layout is not "/superadmin" and requiredRoles match
            (route.layout !== "/superadmin" && route.requiredRoles === test.perm_name && (
              route.name !== "TestTask" &&
              route.name !== "Template" &&
              route.name !== "Email Settings"
            )) ||
            // Check if the layout is "/superadmin" and route name does not match any of the specified names
            (route.layout === "/superadmin" && (
              route.name !== "TestTask" &&
              route.name !== "Template" &&
              route.name !== "Email Settings"
            ))
          )
        );
        accumulatedRoutes.push(...filteredRoute);
      });
    } else {
      // Process when roles are not present
      const filteredRoute = routes.filter(
        (route) => (
          // Check if the layout is not "/superadmin" and requiredRoles match
          route.layout === "/superadmin" && !route.requiredRoles
        )
      );
  
      accumulatedRoutes.push(...filteredRoute);
    }
  
    // Map the accumulated routes in the order of the original routes array
    const orderedRoutes = routes.map((route) =>
      accumulatedRoutes.find(
        (filteredRoute) => filteredRoute && filteredRoute.name === route.name
      )
    );
  
    setFilteredRoutes(orderedRoutes.filter(Boolean));
  }, []);
  
  
  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          _hover={{ cursor: "pointer" }}
        />
      </Flex>

      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w={`${expand === true ? "220px" : "60px"}`}
          maxW={`${expand === true ? "220px" : "60px"}`}
          bg={sidebarBackgroundColor}
        >
          <DrawerCloseButton
            zIndex="3"
            onClose={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody
            maxW={`${expand === true ? "220px" : "60px"}`}
            px="0rem"
            pb="0"
          >
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Content routes={filteredRoutes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
