import { Portal, Box, useDisclosure, Flex, Spinner } from "@chakra-ui/react";
// import { useScreenshot, createFileName } from "use-react-screenshot";
import Footer from "components/footer/FooterAdmin.js";
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import { Suspense, createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { setRoute } from "reducer/Initializer";
import routes from "routes.js";
import { MdOutlinePhotoCameraBack } from "react-icons/md";
import * as htmlToImage from "html-to-image";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_BASE_URL);
socket.emit('message','front end');
// Assuming you have a socket instance set up
socket.on('notification', function(msg) {
  // Handle the received message here
  // console.log('Received notification:', msg);
});



const createFileName = (extension = "", ...names) => {
  if (!extension) {
    return "";
  }

  return `${names.join("")}.${extension}`;
};
export default function Dashboard(props) {
  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const user = useSelector((state) => state.app.app.user);
  const dispatch = useDispatch();
  let Expand = useSelector((state) => state.app.app.isExpanded);
  const location = useLocation();
  const ref = createRef(null);
  const takeScreenShot = async (node) => {
    const originalBackgroundColor = node.style.backgroundColor;

  // Set the background color to white
  node.style.backgroundColor = 'white';

  // Take the screenshot
  const dataURI = await htmlToImage.toJpeg(node);

  // Restore the original background color
  node.style.backgroundColor = originalBackgroundColor;

  return dataURI;
  };

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  useEffect(() => {
    const activeRoute = getActiveRoute(routes);
    dispatch(setRoute(activeRoute));
  }, [location.pathname]);

  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Prolink";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(
            routes[i].both === true
              ? routes[i].path.replace("/:id", "")
              : routes[i].layout + routes[i].path.replace("/:id", "")
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const under = (routes) => {
    let activeRoute = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(
            routes[i].layout + routes[i].path.replace("/:id", "")
          ) !== -1
        ) {
          return routes[i];
        }
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }

    return activeNavbar;
  };

  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (!prop.under && prop.layout === "/admin") {
        return (
          <Route
            path={prop.both === true ? prop.path : prop.layout + prop.path}
            element={<prop.component />}
            key={key}
          />
        );
      } else if (prop.under && prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            element={<prop.component />}
            key={key}
          />
        );
      }

      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  return (
    <Box>
      <Box>
        <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
          {/* <button style={{
            position: "fixed",
            zIndex: 999,
            right:"10%",
            fontSize:"1.6rem",
            color:"#4312e0",
            top:"47px"
          }}    onClick={downloadScreenshot}><MdOutlinePhotoCameraBack/></button>
          <div ref={ref} style={{
       position: "absolute",
       width: "100%",
        }}> */}
         {user.role==="admin"&& <button style={{
            position: "fixed",
            zIndex: 999,
            right:"10%",
            fontSize:"1.6rem",
            color:"#4312e0",
            top:"47px"
          }} onClick={downloadScreenshot}><MdOutlinePhotoCameraBack/></button>}
        <div ref={ref}  style={{
       position: "absolute",
       width: "100%",
        }}>
            <Sidebar routes={routes} expand={Expand} display="none" {...rest} />
            <Box
              float="right"
              minHeight="100vh"
              height="100%"
              position="relative"
              maxHeight="100%"
              w={{
                base: "100%",
                xl: Expand ? "calc(100% - 220px)" : "calc(100% - 60px)"
              }}
              maxWidth={{
                base: "100%",
                xl: Expand ? "calc(100% - 220px)" : "calc(100% - 60px)"
              }}
              transition="all 0.4s cubic-bezier(0.685, 0.0473, 0.346, 1)"
              transitionDuration=".2s, .2s, .35s"
              transitionProperty="top, bottom, width"
              transitionTimingFunction="linear, linear, ease"
            >
              <Portal>
                <Box>
                  <Navbar
                    onOpen={onOpen}
                    logoText={"Horizon UI Dashboard PRO"}
                    brandText={getActiveRoute(routes)}
                    secondary={getActiveNavbar(routes)}
                    message={getActiveNavbarText(routes)}
                    fixed={fixed}
                    under={under(routes)}
                    {...rest}
                  />
                </Box>
              </Portal>
              <Box mt={{ base: "150px", md: "87px", xl: "87px" }}>
                {getRoute() ? (
                  <Box
                    mx="auto"
                    p={{ base: "20px", md: "30px" }}
                    pe="20px"
                    pt="50px"
                  >
                    <Suspense
                      fallback={
                        <Flex
                          justifyContent={"center"}
                          alignItems={"center"}
                          width="100%"
                        >
                          <Spinner />
                        </Flex>
                      }
                    >
                      <Routes>
                        {getRoutes(routes)}
                        <Route
                          path="/*"
                          element={<Navigate to="/admin/default" />}
                        />
                      </Routes>
                    </Suspense>
                  </Box>
                ) : null}
              </Box>
              <Box
                style={{
                  position: "absolute",
                  bottom: "0px"
                }}
              >
                <Footer />
              </Box>
            </Box>
          </div>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}
