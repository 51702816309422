import { Icon } from "@chakra-ui/react";
import { HiUsers } from "react-icons/hi";
import {
  MdContacts,
  MdDirectionsWalk,
  MdHome,
  MdInsertChartOutlined,
  MdLeaderboard,
  MdLock,
} from "react-icons/md";
// icon
// Test Commit
import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { FaUserGear } from "react-icons/fa6";
import { FaCalendarAlt, FaTasks } from "react-icons/fa";
import { LuBuilding2 } from "react-icons/lu";
import { PiPhoneCallBold } from "react-icons/pi";
import { RiOpenSourceFill } from "react-icons/ri";
import { CgTemplate } from "react-icons/cg";
import { VscOrganization } from "react-icons/vsc";
import { IoIosSettings } from "react-icons/io";
import { MdOutlinePhoneMissed } from "react-icons/md";
// Admin Imports
const MainDashboard = React.lazy(() => import("views/admin/default"));
const UserDashboard = React.lazy(() => import("views/admin/default"));

// My component
const Contact = React.lazy(() => import("views/admin/contact"));
const ContactView = React.lazy(() => import("views/admin/contact/View"));

const User = React.lazy(() => import("views/admin/users"));
const UserView = React.lazy(() => import("views/admin/users/View"));
// const Useradmin = React.lazy(() => import("views/admin/users"));
// const UserViewadmin = React.lazy(() => import("views/admin/users/View"));
const Property = React.lazy(() => import("views/admin/property"));
const PropertyView = React.lazy(() => import("views/admin/property/View"));

const Lead = React.lazy(() => import("views/admin/lead"));
const LeadView = React.lazy(() => import("views/admin/lead/View"));
const leadCallView = React.lazy(() =>
  import("views/admin/lead/CallDetailview")
);
const LeadExcelTable = React.lazy(() =>
  import("views/admin/lead/components/LeadExcelTable")
);
const Communication = React.lazy(() => import("views/admin/communication"));

const Task = React.lazy(() => import("views/admin/task"));
const TaskView = React.lazy(() =>
  import("views/admin/task/components/taskView")
);
const Calender = React.lazy(() => import("views/admin/calender"));
const Payments = React.lazy(() => import("views/admin/payments"));

const Document = React.lazy(() => import("views/admin/document"));

const EmailHistory = React.lazy(() => import("views/admin/emailHistory"));
const EmailHistoryView = React.lazy(() =>
  import("views/admin/emailHistory/View")
);

const Meeting = React.lazy(() => import("views/admin/meeting"));
const MettingView = React.lazy(() => import("views/admin/meeting/View"));

const PhoneCall = React.lazy(() => import("views/admin/phoneCall"));
const PhoneCallView = React.lazy(() => import("views/admin/phoneCall/View"));
const MissedCall = React.lazy(() => import("views/admin/missedCall"));
// const MissedCallView = React.lazy(() => import("views/admin/missedCall/View"));
const Report = React.lazy(() => import("views/admin/reports"));

// const TextMsg = React.lazy(() => import("views/admin/textMsg"));
// const TextMsgView = React.lazy(() => import("views/admin/textMsg/View"));
// lead source import
const LeadSource = React.lazy(() => import("views/admin/leadSource"));
const LeadSourceView = React.lazy(() => import("views/admin/leadSource/View"));
// template import
const Template = React.lazy(() => import("views/admin/Template"));
const TemplateView = React.lazy(() => import("views/admin/Template/View"));
// Role import
const UserRole = React.lazy(() => import("views/admin/MasterRole"));
const UserRoleView = React.lazy(() => import("views/admin/MasterRole/View"));
// Email Settings import
const Settings = React.lazy(() => import("views/admin/settings"));
const SettingsView = React.lazy(() => import("views/admin/settings/View"));
// Super Admin import
const SuperAdminRole = React.lazy(() => import("views/superAdmin/companies"));
const SuperAdminView = React.lazy(() => import("views/superAdmin/companies/View"));
// Auth Imports
const SignInCentered = React.lazy(() => import("views/auth/signIn"));

const routes = [
  // ========================== Dashboard ==========================
  {
    name: "Dashboard",
    layout: "/admin",
    // both: true,
    path: "/default",
    // under: "dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
    requiredRoles: "get_lead",
  },
  // {
  //   name: "Dashboard",
  //   layout: "/superadmin",
  //   path: "/default",
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: UserDashboard,
  //   requiredRoles: "get_text-msg",
  // },
  // {
  //   name: "Dashboard",
  //   layout: "/user",
  //   path: "/default",
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: UserDashboard,
  //   requiredRoles: "get_lead",
  // },
  // ==========================other agent Layout ==========================

  // ==========================Super Admin Layout ==========================

  {
    name: "Companies",
    layout: "/superadmin",
    path: "/company",
    icon: <Icon as={VscOrganization} width="20px" height="20px" color="inherit" />,
    component: SuperAdminRole,
  },
  {
    name: "Company View",
    layout: "/superadmin",
    under: "company",
    path: "/companyView/:id",
    component: SuperAdminView,
  },
  // ========================== Admin Layout ==========================
  // ------------- lead Routes ------------------------
  {
    name: "Leads",
    layout: "/admin",
    both: true,
    path: "/lead",
    icon: (
      <Icon as={MdLeaderboard} width="20px" height="20px" color="inherit" />
    ),
    component: Lead,
    requiredRoles: "get_lead",
  },
  {
    name: "Lead View",
    layout: "/admin",
    both: true,
    under: "lead",
    path: "/leadView/:id",
    component: LeadView,
    requiredRoles: "get_lead",
  },
  {
    name: "Lead View",
    layout: "/admin",
    both: true,
    under: "lead",
    path: "/leadCallView/:id",
    component: leadCallView,
    requiredRoles: "get_lead",
  },
  {
    name: "Lead View",
    layout: "/admin",
    both: true,
    under: "lead",
    path: "/leadExcelView",
    component: LeadExcelTable,
    requiredRoles: "get_lead",
  },
  // // --------------- contact Routes --------------------
  // {
  //   name: "Contacts",
  //   layout: "/admin",
  //   both: true,
  //   path: "/contacts",
  //   icon: <Icon as={MdContacts} width="20px" height="20px" color="inherit" />,
  //   component: Contact,
  //   requiredRoles: "get_contact",
  // },
  // {
  //   name: "Contacts View",
  //   layout: "/admin",
  //   both: true,
  //   under: "contacts",
  //   path: "/contactView/:id",
  //   component: ContactView,
  //   requiredRoles: "get_contact",
  // },
  // ------------- Property Routes ------------------------
  {
    name: "Projects",
    layout: "/admin",
    both: true,
    path: "/projects",
    icon: <Icon as={LuBuilding2} width="20px" height="20px" color="inherit" />,
    component: Property,
    requiredRoles: "get_property",
  },
  {
    name: "Project View",
    layout: "/admin",
    both: true,
    under: "projects",
    path: "/projectView/:id",
    component: PropertyView,
    requiredRoles: "get_property",
  },

  // // ------------- Communication Integration Routes ------------------------
  // {
  //   name: "Communication",
  //   layout: "/admin",
  //   both: true,
  //   path: "/communication",
  //   icon: (
  //     <Icon
  //       as={GiSatelliteCommunication}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: Communication,
  //   requiredRoles: "get_email",
  // },
  // ------------- Task Routes ------------------------
  {
    name: "TestTask",
    layout: "/admin",
    both: true,
    path: "/task",
    icon: <Icon as={FaTasks} width="20px" height="20px" color="inherit" />,
    component: Task,
    requiredRoles: "get_task",
  },
  {
    name: "TestTask View",
    layout: "/admin",
    both: true,
    under: "task",
    path: "/view/:id",
    component: TaskView,
    requiredRoles: "get_task",
  },
  // ------------- Meeting Routes ------------------------
  {
    name: "Site Visits",
    layout: "/admin",
    both: true,
    path: "/sitevisit",
    icon: <Icon as={MdDirectionsWalk} width="20px" height="20px" color="inherit" />,
    component: Meeting,
    requiredRoles: "get_task",
  },
  {
    name: "Site View",
    layout: "/admin",
    both: true,
    under: "sitevisit",
    path: "/sitevisitview/:id",
    component: MettingView,
    requiredRoles: "get_task",
  },
  // ------------- Phone Routes ------------------------
  {
    name: "Calls",
    layout: "/admin",
    both: true,
    path: "/phone-call",
    icon: (
      <Icon as={PiPhoneCallBold} width="20px" height="20px" color="inherit" />
    ),
    component: PhoneCall,
    requiredRoles: "get_phoneCall",
  },
  {
    name: "Call View",
    layout: "/admin",
    both: true,
    under: "phone-call",
    path: "/phone-call/:id",
    component: PhoneCallView,
    requiredRoles: "get_phoneCall",
  },

    // ------------- missed call Routes ------------------------
    {
      name: "Missed Calls",
      layout: "/admin",
      both: true,
      path: "/missed-calls",
      icon: (
        <Icon as={MdOutlinePhoneMissed} width="20px" height="20px" color="inherit" />
      ),
      component: MissedCall,
      requiredRoles: "get_phoneCall",
    },

  // ------------- Email Routes------------------------
  {
    // separator: 'History',
    name: "Emails",
    layout: "/admin",
    both: true,
    path: "/email",
    icon: (
      <Icon as={AiOutlineMail} width="20px" height="20px" color="inherit" />
    ),
    component: EmailHistory,
    requiredRoles: "get_email",
  },
  {
    name: "Email View",
    layout: "/admin",
    both: true,
    under: "email",
    path: "/Email/:id",
    component: EmailHistoryView,
    requiredRoles: "get_email",
  },


  // ========================== template layout start===============
  {
    name: "Template",
    layout: "/admin",
    both: true,
    path: "/template",
    icon: <Icon as={CgTemplate} width="20px" height="20px" color="inherit" />,
    component: Template,
    requiredRoles: "get_template",
  },
  {
    name: "Template View",
    layout: "/admin",
    both: true,
    under: "Template",
    path: "/templateView/:id",
    component: TemplateView,
    requiredRoles: "get_template",
  },
  // ========================== template layout end===============

  // ========================== MasterRole layout start===============
  {
    name: "Role",
    layout: "/admin",
    both: true,
    path: "/role",
    icon: <Icon as={FaUserGear} width="20px" height="20px" color="inherit" />,
    component: UserRole,
    requiredRoles: "get_role",
  },
  {
    name: "Role View",
    layout: "/admin",
    both: true,
    under: "Role",
    path: "/roleView/:id",
    component: UserRoleView,
    requiredRoles: "get_role",
  },
  {
    name: "User",
    layout: "/admin",
    both: true,
    path: "/users",
    icon: <Icon as={HiUsers} width="20px" height="20px" color="inherit" />,
    component: User,
    requiredRoles: "get_user",
  },
  {
    name: "User View",
    layout: "/admin",
    both: true,
    under: "User",
    path: "/userView/:id",
    component: UserView,
    requiredRoles: "get_user",
  },
  // ========================== MasterRole layout end===============

  // ------------- Calender Routes ------------------------
  {
    name: "Tasks",
    layout: "/admin",
    both: true,
    path: "/calender",
    icon: (
      <Icon as={FaCalendarAlt} width="20px" height="20px" color="inherit" />
    ),
    component: Calender,
    requiredRoles: "get_task",
  },
  // ==========================master layout start==============
  {
    name: "Master",
    layout: "/admin",
    both: true,
    path: "/master",
    icon: (
      <Icon as={RiOpenSourceFill} width="20px" height="20px" color="inherit" />
    ),
    component: LeadSource,
    requiredRoles: "get_master",
  },
  {
    name: "Master View",
    layout: "/admin",
    both: true,
    under: "Master",
    path: "/masterView/:id",
    component: LeadSourceView,
    requiredRoles: "get_master",
  },

  // ==========================master layout end===============

  // ------------- email setting Routes ------------------------
  {
    name: "Email Settings",
    layout: "/admin",
    both: true,
    path: "/settings",
    icon: <Icon as={IoIosSettings} width="20px" height="20px" color="inherit" />,
    component: Settings,
    requiredRoles: "get_email",
  },
  {
    name: "Settings View",
    layout: "/admin",
    both: true,
    under: "Email Settings",
    path: "/settingsView/:id",
    component: SettingsView,
    requiredRoles: "get_email",
  },
  // ------------- email setting Routes  end------------------------

  // ------------- Payments Routes ------------------------
  // {
  //   name: "Payments",
  //   layout: "/admin",
  //   both: true,
  //   path: "/payments",
  //   icon: <Icon as={FaRupeeSign} width='20px' height='20px' color='inherit' />,
  //   component: Payments,
  // },
  // // ------------- Text message Routes ------------------------
  // {
  //   name: "Text Msg",
  //   layout: "/admin",
  //   both: true,
  //   path: "/text-msg",
  //   icon: <Icon as={MdOutlineMessage} width='20px' height='20px' color='inherit' />,
  //   component: TextMsg,
  // },
  // {
  //   name: "Text Msg View",
  //   layout: "/admin",
  //   both: true,
  //   under: "text-msg",
  //   path: "/text-msg/:id",
  //   component: TextMsgView,
  // },
  // ------------- Document Routes ------------------------
  // {
  //   name: "Documents",
  //   layout: "/admin",
  //   both: true,
  //   path: "/documents",
  //   icon: (
  //     <Icon as={AiFillFolderOpen} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: Document,
  //   requiredRoles: "get_document",
  // },
  // ----------------- Reporting Layout -----------------
  {
    name: "Report",
    layout: "/admin",
    both: true,
    path: "/reporting-analytics",
    icon: (
      <Icon
        as={MdInsertChartOutlined}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Report,
    requiredRoles: "get_reporting",
  },
  // ------------- email setting Routes ------------------------
  // {
  //   name: "Email Settings",
  //   layout: "/admin",
  //   path: "/users",
  //   icon: <Icon as={IoIosSettings } width="20px" height="20px" color="inherit" />,
  //   component: User,
  //   requiredRoles: "get_user",
  // },
  // {
  //   name: "User View",
  //   layout: "/admin",
  //   under: "user",
  //   path: "/user-view/:id",
  //   component: UserView,
  //   requiredRoles: "get_user",
  // },

  // {
  //   name: "Users",
  //   layout: "/admin",
  //   path: "/user",
  //   icon: <Icon as={HiUsers} width="20px" height="20px" color="inherit" />,
  //   component: User,
  //   requiredRoles: "get_user",
  // },
  // {
  //   name: "User View",
  //   both: true,
  //   layout: "/admin",
  //   under: "user",
  //   path: "/userView/:id",
  //   component: UserView,
  //   requiredRoles: "get_user",
  // },

  // ========================== User layout ==========================

  // ========================== auth layout ==========================
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    // requiredRoles: "get_user",
  },
];

export default routes;
