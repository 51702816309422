import { createSlice } from "@reduxjs/toolkit";

const Initializer = createSlice({
  name: "app",
  initialState: {
    isExpanded: true,
    route: "",
    leadCallData: {},
    masterStatus: "",
    templateContent: "",
    commonData: {},
    routeData: "",
    routesPermission: "",
    dashboardValue: {},
    superAdminData: "",
    user: "",
    roles: "",
    leadStatus: "",
    emailSettings: "",
    refresh: "",
    dragData: "",
    audioData: "",
    filterStatus: false,
    statusData: {},
    userData:"",
    projectList:"",
    subSourceId:"",
    leadSource:"",
    leadSubSource:"",
  },
  reducers: {
    setExpand: (state, actions) => {
      state.isExpanded = actions.payload;
    },
    setRoute: (state, actions) => {
      state.route = actions.payload;
    },
    setLeadCallData: (state, actions) => {
      state.leadCallData = actions.payload;
    },
    setSubSourceId: (state, actions) => {
      state.subSourceId = actions.payload;
    },
    setLeadSource: (state, actions) => {
      state.leadSource = actions.payload;
    },
    setMasterStatus: (state, actions) => {
      state.masterStatus = actions.payload;
    },
    setLeadSubSource: (state, actions) => {
      state.leadSubSource = actions.payload;
    },
    setTemplateData: (state, actions) => {
      state.templateContent = actions.payload;
    },
    setCommonData: (state, actions) => {
      state.commonData = actions.payload;
    },
    setRouteData: (state, actions) => {
      state.routeData = actions.payload;
    },
    setRoutePermission: (state, actions) => {
      state.routesPermission = actions.payload;
    },
    setDashboardValue: (state, actions) => {
      state.dashboardValue = actions.payload;
    },
    setSuperAdminData: (state, actions) => {
      state.superAdminData = actions.payload;
    },
    setUser: (state, actions) => {
      state.user = actions.payload;
    },
    setUserData: (state, actions) => {
      state.userData = actions.payload;
    },
    setRoles: (state, actions) => {
      state.roles = actions.payload;
    },
    setLeadStatus: (state, actions) => {
      state.leadStatus = actions.payload;
    },
    setEmailSettings: (state, actions) => {
      state.emailSettings = actions.payload;
    },
    setDragData: (state, actions) => {
      state.dragData = actions.payload;
    },
    setAudioData: (state, actions) => {
      state.audioData = actions.payload;
    },
    setFilterStatus: (state, actions) => {
      state.filterStatus = actions.payload;
    },
    setStatusData: (state, actions) => {
      state.statusData = actions.payload;
    },
    setProjectList: (state, actions) => {
      state.projectList = actions.payload;
    },
    clearAppState: (state) => {
      state.templateContent = null;
    },
  },
});

export const {
  setExpand,
  setRoute,
  setLeadCallData,
  setTemplateData,
  clearAppState,
  setCommonData,
  setRouteData,
  setRoutePermission,
  setDashboardValue,
  setSuperAdminData,
  setUser,
  setRoles,
  setLeadStatus,
  setEmailSettings,
  setDragData,
  setAudioData,
  setFilterStatus,
  setStatusData,
  setUserData,
  setProjectList,
  setMasterStatus,
  setSubSourceId,
  setLeadSource,
  setLeadSubSource,
} = Initializer.actions;

const appReducer = Initializer.reducer;

export default appReducer;
